import config from '@/config'
import { event } from 'vue-gtag'
import store from '@/stores/index'

export const useCustomEventTracking = (action, area, customParams = {}) => {
  if (config.STAGE !== 'app' || window.Cypress) {
    return
  }

  event(action, {
    event_category: area,
    ...customParams,
  })
}

export const trackUserNavigation = async (area, route) => {
  const payload = { path: route.path }
  const queryString = route.fullPath.indexOf('?')

  if (area || route.hash) {
    payload.anchor = area ?? route.hash
  }

  if (queryString > 0) {
    payload.query = route.fullPath.slice(queryString + 1, route.fullPath.length)
  }

  if (store.getters['User/user']) {
    await store.dispatch('User/navigate', payload)
  }
}
